
import {computed, defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'Metric',
  props: {
    title: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    icon: {
      type: [String, Array] as PropType<Icon>,
    },
    color: {
      type: String,
    },
    clickable: {
      type: Boolean,
    }
  },
  emits: [
    'click',
  ],
  setup(props: MetricProps, {emit}) {
    const style = computed<Partial<CSSStyleDeclaration>>(() => {
      const {color} = props;
      return {
        backgroundColor: color,
      };
    });

    const onClick = () => {
      const {clickable} = props;
      if (!clickable) return;
      emit('click');
    };

    return {
      style,
      onClick,
    };
  },
});
